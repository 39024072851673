import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import i18n from '@/libs/i18n/index'
import PropertyImagesController from '@/controllers/property-images.controller'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { formatCurrency } from '@core/utils/filter'

export default function usePropertyList() {
  // Use toast
  const toast = useToast()

  const refPropertyListTable = ref(null)

  // Table Handlers
  const tableColumns = computed(() => {
    return [
      {
        key: 'images',
        label: i18n.t('propertiesTable.image'),
        sortable: false,
      },
      {
        key: 'ref',
        label: i18n.t('propertiesModel.general.ref'),
        sortable: true,
      },
      {
        key: 'province',
        label: i18n.t('addressInformations.province'),
        sortable: true,
      },
      {
        key: 'city',
        label: i18n.t('addressInformations.city'),
        sortable: true,
      },
      {
        key: 'qtyBedrooms',
        label: i18n.t('propertiesTable.qtyBedrooms'),
        sortable: true,
      },
      {
        key: 'price',
        label: i18n.t('propertiesModel.general.price'),
        sortable: true,
        formatter: formatCurrency,
      },
      { key: 'actions', label: '' },
    ]
  })

  const onlyViewTableColumns = computed(() => {
    return [
      {
        key: 'ref',
        label: i18n.t('propertiesModel.general.ref'),
        sortable: true,
      },
      {
        key: 'province',
        label: i18n.t('addressInformations.province'),
        sortable: true,
      },
      {
        key: 'city',
        label: i18n.t('addressInformations.city'),
        sortable: true,
      },
      {
        key: 'qtyBedrooms',
        label: i18n.t('propertiesTable.qtyBedrooms'),
        sortable: true,
      },
      {
        key: 'price',
        label: i18n.t('propertiesModel.general.price'),
        sortable: true,
        formatter: formatCurrency,
      },
      {
        key: 'rejected',
        label: i18n.t('propertiesModel.general.rejected'),
        sortable: true,
      },
      { key: 'viewActions', label: '' },
    ]
  })

  const perPage = ref(10)
  const totalProperty = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const ownerQuery = ref('')
  const dniQuery = ref('')
  const emailQuery = ref('')
  const telephoneQuery = ref('')
  const cellphoneQuery = ref('')
  const availableStatusQuery = ref(false)
  const reservedStatusQuery = ref(false)
  const soldStatusQuery = ref(false)
  const favoriteStatusQuery = ref(false)
  const refQuery = ref('')
  const bedroomsQuery = ref('')
  const zonesQuery = ref([])
  const propertyActionsQuery = ref([])
  const propertyTypesQuery = ref([])
  const minPriceQuery = ref('')
  const maxPriceQuery = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refPropertyListTable.value
      ? refPropertyListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProperty.value,
    }
  })

  const refetchData = () => {
    refPropertyListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  async function uploadFiles(files, id, order) {
    const propertyImgController = new PropertyImagesController()
    await propertyImgController.addImagesToProperty(id, files, order)
  }

  const resolveRejectedVariant = (status) => {
    status = status ? status : false
    if (status === true) return 'warning'
    if (status === false) return 'success'
    return 'primary'
  }

  const resolveRejectedText = (status) => {
    status = status ? status : false
    if (status === true) return i18n.t('propertiesModel.visibility.rejected')
    if (status === false) return i18n.t('propertiesModel.visibility.available')
    return '-'
  }

  const onSubmit = (data) => {
    const order = []
    let files = []

    if (data.images) {
      data.images.forEach((element, index) => {
        element.orderPosition = index
        order.push({
          filename: element.filename ?? element.name,
          position: index,
        })
      })

      files = data.images.filter((element) => element instanceof File)
      data.images = data.images.filter((element) => !(element instanceof File))
    }

    store
      .dispatch('app-property/setProperty', data)
      .then(async (res) => {
        if (files.length > 0) await uploadFiles(files, res.data.id, order)

        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toasts.save.success'),
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

        router.push({ name: 'property-list' })
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toasts.save.error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchProperty = (ctx, callback) => {
    store
      .dispatch('app-property/fetchProperty', {
        headers: {
          'X-Sort': `properties.${sortBy.value}`,
          'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
        },
        query: {
          owner: ownerQuery.value,
          dni: dniQuery.value,
          email: emailQuery.value,
          telephone: telephoneQuery.value,
          cellphone: cellphoneQuery.value,
          available: availableStatusQuery.value ? 1 : '',
          reserved: reservedStatusQuery.value ? 1 : '',
          sold: soldStatusQuery.value ? 1 : '',
          favorite: favoriteStatusQuery.value ? 1 : '',
          ref: refQuery.value,
          zones: zonesQuery.value.map((z) => z.label).join('|'),
          bedrooms: bedroomsQuery.value,
          propertyActions: propertyActionsQuery.value
            .map((pa) => pa.label)
            .join('|'),
          propertyTypes: propertyTypesQuery.value
            .map((ptp) => ptp.key)
            .join('|'),
          minPrice: minPriceQuery.value,
          maxPrice: maxPriceQuery.value,
          page: currentPage.value,
          perPage: perPage.value,
        },
      })
      .then((res) => {
        const { total } = res
        totalProperty.value = total
        callback(res.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toasts.fetch.error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteProperty = (id) => {
    store
      .dispatch('app-property/deleteProperty', id)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toasts.delete.success'),
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

        refetchData()
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toasts.delete.error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const rejectProperty = (propertyId, demandId) => {
    const payload = {
      propertyId: propertyId,
      demandId: demandId,
    }
    store
      .dispatch('app-property/rejectProperty', payload)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toasts.reject.success'),
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

        refetchData()
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toasts.reject.error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    rejectProperty,
    fetchProperty,
    tableColumns,
    onlyViewTableColumns,
    perPage,
    currentPage,
    totalProperty,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refPropertyListTable,

    ownerQuery,
    dniQuery,
    emailQuery,
    telephoneQuery,
    cellphoneQuery,
    availableStatusQuery,
    reservedStatusQuery,
    soldStatusQuery,
    favoriteStatusQuery,
    refQuery,
    zonesQuery,
    bedroomsQuery,
    propertyActionsQuery,
    propertyTypesQuery,
    minPriceQuery,
    maxPriceQuery,

    refetchData,
    onSubmit,
    deleteProperty,

    resolveRejectedVariant,
    resolveRejectedText,
  }
}
