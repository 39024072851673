<template>
  <b-modal
    v-model="modal"
    :title="title"
    :ok-title="$t('buttons.ok')"
    :cancel-title="$t('buttons.cancel')"
    cancel-variant="outline-secondary"
    @close="close"
    @cancel="close"
    @ok="callback"
  >
    <p>{{ message }}</p>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'

export default {
  components: {
    BModal,
  },
  props: {
    show: {
      type: Boolean,
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      modal: false,
    }
  },
  watch: {
    show() {
      this.modal = this.show
    },
  },
  methods: {
    close() {
      this.modal = false
      this.$emit('close')
    },
    callback() {
      this.modal = false
      this.$emit('action')
    },
  },
}
</script>
