import { useModule } from '@/controllers/manager'

export default {
    namespaced: true,
    state: {
        module: 'property',
        loading: false,
    },
    getters: {},
    mutations: {
        SET_LOADING(state, payload) {
            state.loading = payload
        },
    },
    actions: {
        async rejectProperty({ state, commit }, payload) {
            const controller = useModule(state.module);
            try {
                return await controller.reject(payload);
            } catch (e) {
                throw e
            }
        },
        async fetchProperty({ state, commit }, payload) {
            commit('SET_LOADING', true)
            const controller = useModule(state.module)
            try {
                return await controller.getAll(payload)
            } catch (e) {
                throw e
            } finally {
                commit('SET_LOADING', false)
            }
        },
        async fetchOneProperty({ state, commit }, payload) {
            commit('SET_LOADING', true)
            const controller = useModule(state.module)
            try {
                return await controller.getById(payload)
            } catch {
                throw e
            } finally {
                commit('SET_LOADING', false)
            }
        },
        async setProperty({ commit, state }, payload) {
            commit('SET_LOADING', true)
            const controller = useModule(state.module)
            try {
                if (payload.id) {
                    const res = await controller.update(payload)
                    if (payload.propertyTerraces.length > 0)
                        await controller.addPropertyTerraces(payload.id, payload.propertyTerraces)
                    return res
                } else {
                    const res = await controller.create(payload)
                    if (payload.propertyTerraces.length > 0)
                        await controller.addPropertyTerraces(res.data.id, payload.propertyTerraces)
                    return res
                }
            } catch (e) {
                throw e
            } finally {
                commit('SET_LOADING', false)
            }
        }, async deleteProperty({ commit, state }, payload) {
            commit('SET_LOADING', true)
            const controller = useModule(state.module)
            try {
                await controller.deleteById(payload)
            } catch (e) {
                throw e
            } finally {
                commit('SET_LOADING', false)
            }
        },
        async generatePdf({ commit, state }, payload) {
            commit('SET_LOADING', true)
            const controller = useModule(state.module)
            try {
                return await controller.generatePdf(payload)
            } catch (e) {
                throw e
            } finally {
                commit('SET_LOADING', false)
            }
        }
    },
}
