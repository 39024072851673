import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import i18n from '@/libs/i18n/index'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function usePropertyTypesList() {
  // Use toast
  const toast = useToast()

  const refPropertyTypesListTable = ref(null)

  // Table Handlers
  const tableColumns = computed(() => {
    return [
      {
        key: 'titleEn',
        label: i18n.t('propertyTypesModel.titleEnglish'),
        sortable: true,
      },
      {
        key: 'titleEs',
        label: i18n.t('propertyTypesModel.titleSpanish'),
        sortable: true,
      },
      {
        key: 'titleSw',
        label: i18n.t('propertyTypesModel.titleSwedish'),
        sortable: true,
      },
      { key: 'actions', label: '' },
    ]
  })
  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const searchEnQuery = ref('')
  const searchEsQuery = ref('')
  const searchSwQuery = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refPropertyTypesListTable.value
      ? refPropertyTypesListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    refPropertyTypesListTable.value.refresh()
  }

  watch(
    [currentPage, perPage, searchEnQuery, searchEsQuery, searchSwQuery],
    () => {
      refetchData()
    },
  )

  const onSubmit = (data) => {
    store
      .dispatch('app-property-types/setPropertyType', data)
      .then(() => {
        router.push({ name: 'property-types' })
      })
      .catch((e) => {
        console.error(e)
      })
  }

  const fetchPropertyTypes = (ctx, callback) => {
    store
      .dispatch('app-property-types/fetchPropertyTypes', {
        headers: {
          'X-Sort': `property_types.${sortBy.value}`,
          'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
          'X-Paginate': true,
        },
        query: {
          searchEn: searchEnQuery.value,
          searchEs: searchEsQuery.value,
          searchSw: searchSwQuery.value,
          page: currentPage.value,
          perPage: perPage.value,
        },
      })
      .then((res) => {
        const { total } = res
        totalItems.value = total
        callback(res.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toasts.fetch.error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const listPropertyTypes = () =>
    new Promise((resolve, reject) => {
      store
        .dispatch('app-property-types/fetchPropertyTypes', {
          headers: {
            'X-Sort': `property_types.${sortBy.value}`,
            'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
            'X-Paginate': false,
          },
          query: {
            searchEn: searchEnQuery.value,
            searchEs: searchEsQuery.value,
            searchSw: searchSwQuery.value,
            page: currentPage.value,
            perPage: perPage.value,
          },
        })
        .then((res) => {
          const { total } = res
          totalItems.value = total
          const parsedProperties = []
          res.data.forEach((element) => {
            parsedProperties.push({
              key: element.id,
              label: {
                en: element.titleEn,
                es: element.titleEs,
                sv: element.titleSw,
              },
            })
          })
          resolve(parsedProperties)
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('toasts.fetch.error'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          reject(e)
        })
    })

  const deletePropertyType = (id) => {
    store
      .dispatch('app-property-types/deletePropertyType', id)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toasts.delete.success'),
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

        refetchData()
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toasts.delete.error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchPropertyTypes,
    listPropertyTypes,
    tableColumns,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchEnQuery,
    searchEsQuery,
    searchSwQuery,
    sortBy,
    isSortDirDesc,
    refPropertyTypesListTable,

    refetchData,
    onSubmit,
    deletePropertyType,
  }
}
