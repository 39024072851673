<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div v-if="!onlyView" class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('commons.show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('commons.entries') }}</label>
          </b-col>

          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="outline-primary"
                class="mr-1"
                @click="showFilters = !showFilters"
              >
                <feather-icon v-if="!showFilters" icon="FilterIcon" />
                <feather-icon v-if="showFilters" icon="XIcon" />
              </b-button>
              <b-button
                variant="primary"
                @click="
                  $router.push({ name: 'property-edit', params: { id: 'new' } })
                "
              >
                <span class="text-nowrap">{{ $t('buttons.create') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <!-- Search -->
        <b-row v-if="showFilters">
          <b-col cols="12" class="d-flex align-items-center mt-1 mb-md-0">
            <b-form-input
              v-model="ownerQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('propertiesTable.owner')"
            />

            <b-form-input
              v-model="emailQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('socialInformations.email')"
            />

            <b-form-input
              v-model="dniQuery"
              class="d-inline-block"
              :placeholder="$t('socialInformations.dni')"
            />
          </b-col>

          <b-col cols="12" class="d-flex align-items-center mt-1 mb-md-0">
            <b-form-input
              v-model="refQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('propertiesModel.general.ref')"
            />

            <b-form-input
              v-model="telephoneQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('socialInformations.phone')"
            />

            <b-form-input
              v-model="cellphoneQuery"
              class="d-inline-block"
              :placeholder="$t('propertiesTable.cellphone')"
            />
          </b-col>
        </b-row>

        <b-row v-if="showFilters">
          <b-col cols="12" class="d-flex align-items-center mt-1 mb-md-0">
            <b-form-input
              v-model="bedroomsQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('propertiesTable.qtyBedrooms')"
            />

            <b-form-input
              v-model="minPriceQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('propertiesTable.minPrice')"
            />

            <b-form-input
              v-model="maxPriceQuery"
              class="d-inline-block"
              :placeholder="$t('propertiesTable.maxPrice')"
            />
          </b-col>

          <b-col cols="12" class="d-flex align-items-center mt-1 mb-md-0">
            <v-select
              v-model="propertyTypesQuery"
              multiple
              :placeholder="$t('headers.propertyTypes')"
              class="mr-1"
              style="flex: 1"
              :options="
                (propertyTypes || []).map((e) => ({
                  ...e,
                  label: e.label[$i18n.locale],
                }))
              "
            >
              <span slot="no-options" @click="$refs.select.open = false">
                {{ $t('alert.select.empty') }}
              </span>
            </v-select>

            <!--<v-select
              v-model="propertyActionsQuery"
              multiple
              :placeholder="$t('headers.propertyActions')"
              class="mr-1"
              style="flex: 1"
              :options="propertyActions || []"
            />-->

            <v-select
              v-model="zonesQuery"
              multiple
              :placeholder="$t('headers.zones')"
              style="flex: 1"
              :options="zones || []"
            >
              <span slot="no-options" @click="$refs.select.open = false">
                {{ $t('alert.select.empty') }}
              </span>
            </v-select>
          </b-col>
        </b-row>

        <b-row
          align-v="center"
          align-h="between"
          class="mt-1"
          v-if="showFilters"
        >
          <b-col
            cols="8"
            class="d-flex align-items-center justify-content-start mb-md-0"
          >
            <label class="mr-1">{{ $t('propertyStatus.title') }}</label>

            <b-form-checkbox v-model="availableStatusQuery" class="mr-1">
              {{ $t('propertyStatus.status.available') }}
            </b-form-checkbox>

            <b-form-checkbox v-model="reservedStatusQuery" class="mr-1">
              {{ $t('propertyStatus.status.reserved') }}
            </b-form-checkbox>

            <b-form-checkbox v-model="soldStatusQuery" class="mr-1">
              {{ $t('propertyStatus.status.sold') }}
            </b-form-checkbox>

            <b-form-checkbox v-model="favoriteStatusQuery">
              {{ $t('propertyStatus.status.favorite') }}
            </b-form-checkbox>
          </b-col>

          <b-col
            cols="4"
            class="d-flex align-items-center justify-content-end mb-md-0"
          >
            <b-button variant="primary" @click="refetchData()">
              <span class="text-nowrap">{{ $t('buttons.search') }}</span>
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refPropertyListTable"
        class="position-relative"
        :items="onlyView ? items : fetchProperty"
        responsive
        :fields="onlyView ? onlyViewTableColumns : tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('commons.empty')"
        :sort-desc.sync="isSortDirDesc"
        :busy="!fetchProperty"
      >
        <!-- Table Loading -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" small />
            <strong class="ml-1">{{ $t('commons.loading') }}</strong>
          </div>
        </template>

        <!-- Property Img -->
        <template #cell(images)="data">
          <a
            v-if="data.item.images.length > 0"
            @click="
              showCarouselModal = true
              selectedItem = data.item
            "
          >
            <b-avatar
              size="100px"
              rounded="lg"
              variant="light"
              style="cursor: pointer"
              :id="`avatar-${data.item.id}`"
              :src="createUrl(data.item.images[0])"
            >
            </b-avatar>
          </a>

          <b-avatar v-else variant="primary" rounded="lg" size="100px">
            <feather-icon icon="HomeIcon" size="50" />
          </b-avatar>
        </template>

        <!-- Column: Rejected -->
        <template #cell(rejected)="data">
          <b-badge
            :variant="`light-${resolveRejectedVariant(data.item.rejected)}`"
            class="text-capitalize"
          >
            {{ resolveRejectedText(data.item.rejected) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-row align-h="end">
            <span
            v-if="data.item.isFavorite"
            class="mr-1 ml-1"
            >
              <feather-icon icon="StarIcon" style="color: orange; width: 20px;height: 20px;"/>
            </span>
            <b-button
              variant="outline-secondary"
              size="sm"
              :id="`edit-${data.item.id}`"
              @click="
                $router.push({
                  name: 'property-edit',
                  params: { id: data.item.id },
                })
              "
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-tooltip
              :target="`edit-${data.item.id}`"
              triggers="hover"
              placement="bottom"
            >
              <b>{{ $t('alert.edit.title') }}</b>
            </b-tooltip>
            <b-button
              class="ml-1"
              variant="outline-danger"
              size="sm"
              :id="`delete-${data.item.id}`"
              @click="
                deleteModal = true
                selectedItem = data.item
              "
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
            <b-tooltip
              :target="`delete-${data.item.id}`"
              triggers="hover"
              placement="bottom"
            >
              <b>{{ $t('alert.delete.title') }}</b>
            </b-tooltip>
          </b-row>
        </template>

        <!-- Column: Actions -->
        <template #cell(viewActions)="data">
          <b-row align-h="end">
            <b-button
              class="ml-1"
              variant="outline-secondary"
              size="sm"
              :id="`view-${data.item.id}`"
              @click="
                $router.push({
                  name: 'property-edit',
                  params: { id: data.item.id },
                })
              "
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-tooltip
              triggers="hover"
              placement="left"
              :target="`view-${data.item.id}`"
            >
              <b>{{ $t('alert.view.title') }}</b>
            </b-tooltip>

            <b-button
              class="ml-1"
              variant="outline-secondary"
              size="sm"
              :id="`email-${data.item.id}`"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-tooltip
              triggers="hover"
              placement="left"
              :target="`email-${data.item.id}`"
            >
              <b>{{ $t('alert.sendEmail.title') }}</b>
            </b-tooltip>

            <b-button
              class="ml-1"
              variant="outline-danger"
              size="sm"
              :id="`cancel-${data.item.id}`"
              @click="rejectProperty(data.item.id, demandId)"
            >
              <feather-icon icon="XIcon" />
            </b-button>
            <b-tooltip
              triggers="hover"
              placement="left"
              :target="`cancel-${data.item.id}`"
            >
              <b>{{ $t('alert.cancel.title') }}</b>
            </b-tooltip>
          </b-row>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row v-if="!onlyView">
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ $t('footer.table.showing') }} {{ dataMeta.from }}
              {{ $t('footer.table.to') }} {{ dataMeta.to }}
              {{ $t('footer.table.of') }} {{ dataMeta.of }}
              {{ $t('footer.table.entries') }}</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalProperty"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <AlertDialog
      :id="`confirmation-${selectedItem.id}`"
      :show="deleteModal"
      :message="$t('alert.delete.message')"
      :title="`${$t('alert.delete.title')} ${selectedItem.name}`"
      @action="
        deleteProperty(selectedItem.id)
        selectedItem = {}
        deleteModal = false
      "
      @close="deleteModal = false"
    />

    <!-- Images carousel modal -->
    <b-modal
      hide-footer
      no-close-on-backdrop
      size="lg"
      :visible="showCarouselModal"
      @close="showCarouselModal = false"
    >
      <b-carousel
        id="carousel-fade"
        fade
        v-model="slide"
        :interval="4000"
        controls
        indicators
        background="#ababab"
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <b-carousel-slide
          v-for="(item, i) in selectedItem.images"
          :key="i"
          :img-src="createUrl(item)"
          :caption="`Uploaded on: ${parseUploadedDate(item.created_at)}`"
        >
          <p></p>
        </b-carousel-slide>
      </b-carousel>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BBadge,
  BPagination,
  BSpinner,
  BTooltip,
  BAvatar,
  BCarousel,
  BCarouselSlide,
  BModal,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted, ref } from '@vue/composition-api'

import AlertDialog from '@/components/AlertDialog.vue'

import propertyStoreModule from './propertyStoreModule'
import propertyTypesStoreModule from '../propertyTypes/propertyTypesStoreModule'
import propertyActionsStoreModule from '../propertyActions/propertyActionsStoreModule'
import zonesStoreModule from '../zone/zonesStoreModule'

import usePropertyList from './usePropertyList'
import usePropertyTypesList from '../propertyTypes/usePropertyTypesList'
import usePropertyActionsList from '../propertyActions/usePropertyActionsList'
import useZonesList from '../zone/useZonesList'

import store from '@/store'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BPagination,
    BSpinner,
    BTooltip,
    BAvatar,
    BCarousel,
    BCarouselSlide,
    BModal,
    BFormCheckbox,

    vSelect,
    AlertDialog,
  },

  props: {
    demandId: {
      type: Number,
      default: null,
    },
    onlyView: {
      type: Boolean,
      required: false,
      default: false,
    },
    items: {
      type: Array,
      required: false,
    },
  },

  data() {
    return {
      deleteModal: false,
      selectedItem: {},
      showCarouselModal: false,
      slide: 0,
      sliding: null,
      showFilters: false,
    }
  },

  methods: {
    createUrl(imgObj) {
      return `${process.env.VUE_APP_API_API_PUBLIC_FILES_URL}/${imgObj.path}`
    },
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    },
    parseUploadedDate(dateString) {
      return new Date(dateString).toDateString()
    },
  },
  setup() {
    const PROPERTY_APP_STORE_MODULE_NAME = 'app-property'
    const PROPERTY_TYPES_APP_STORE_MODULE_NAME = 'app-property-types'
    const PROPERTY_ACTIONS_APP_STORE_MODULE_NAME = 'app-property-actions'
    const ZONES_APP_STORE_MODULE_NAME = 'app-zones'

    // Register modules
    if (!store.hasModule(PROPERTY_APP_STORE_MODULE_NAME)) {
      store.registerModule(PROPERTY_APP_STORE_MODULE_NAME, propertyStoreModule)
    }

    if (!store.hasModule(PROPERTY_TYPES_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        PROPERTY_TYPES_APP_STORE_MODULE_NAME,
        propertyTypesStoreModule,
      )
    }

    if (!store.hasModule(PROPERTY_ACTIONS_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        PROPERTY_ACTIONS_APP_STORE_MODULE_NAME,
        propertyActionsStoreModule,
      )
    }

    if (!store.hasModule(ZONES_APP_STORE_MODULE_NAME)) {
      store.registerModule(ZONES_APP_STORE_MODULE_NAME, zonesStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROPERTY_APP_STORE_MODULE_NAME))
        store.unregisterModule(PROPERTY_APP_STORE_MODULE_NAME)
      if (store.hasModule(PROPERTY_TYPES_APP_STORE_MODULE_NAME))
        store.unregisterModule(PROPERTY_TYPES_APP_STORE_MODULE_NAME)
      if (store.hasModule(PROPERTY_ACTIONS_APP_STORE_MODULE_NAME))
        store.unregisterModule(PROPERTY_ACTIONS_APP_STORE_MODULE_NAME)
      if (store.hasModule(ZONES_APP_STORE_MODULE_NAME))
        store.unregisterModule(ZONES_APP_STORE_MODULE_NAME)
    })

    const propertyTypes = ref(null)
    const propertyActions = ref(null)
    const zones = ref(null)

    const { listPropertyTypes } = usePropertyTypesList()
    listPropertyTypes().then((response) => (propertyTypes.value = response))

    const { listPropertyActions } = usePropertyActionsList()
    listPropertyActions().then((response) => (propertyActions.value = response))

    const { listZones } = useZonesList()
    listZones().then((response) => (zones.value = response))

    const {
      fetchProperty,
      tableColumns,
      onlyViewTableColumns,
      perPage,
      currentPage,
      totalProperty,
      dataMeta,
      perPageOptions,
      ownerQuery,
      dniQuery,
      emailQuery,
      telephoneQuery,
      cellphoneQuery,
      availableStatusQuery,
      reservedStatusQuery,
      soldStatusQuery,
      favoriteStatusQuery,
      refQuery,
      zonesQuery,
      bedroomsQuery,
      propertyActionsQuery,
      propertyTypesQuery,
      minPriceQuery,
      maxPriceQuery,
      sortBy,
      isSortDirDesc,
      refPropertyListTable,
      refetchData,
      deleteProperty,
      rejectProperty,

      resolveRejectedVariant,
      resolveRejectedText,
    } = usePropertyList()

    return {
      fetchProperty,
      tableColumns,
      onlyViewTableColumns,
      perPage,
      currentPage,
      totalProperty,
      dataMeta,
      perPageOptions,
      ownerQuery,
      dniQuery,
      emailQuery,
      telephoneQuery,
      cellphoneQuery,
      availableStatusQuery,
      reservedStatusQuery,
      soldStatusQuery,
      favoriteStatusQuery,
      refQuery,
      zonesQuery,
      bedroomsQuery,
      propertyActionsQuery,
      propertyTypesQuery,
      minPriceQuery,
      maxPriceQuery,
      sortBy,
      isSortDirDesc,
      refPropertyListTable,
      refetchData,
      deleteProperty,

      propertyActions,
      propertyTypes,
      zones,
      rejectProperty,

      resolveRejectedVariant,
      resolveRejectedText,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.vs__deselect svg {
  fill: #fff;
}

.dark-layout .vs__deselect svg {
  fill: $primary;
}
</style>
