import { useModule } from '@/controllers/manager'

export default {
    namespaced: true,
    state: {
        module: 'property-types',
        loading: false,
    },
    getters: {},
    mutations: {
        SET_LOADING(state, payload) {
            state.loading = payload
        },
    },
    actions: {
        async fetchPropertyTypes({ state, commit }, payload) {
            commit('SET_LOADING', true)
            const controller = useModule(state.module)
            try {
                return await controller.getAll(payload)
            } catch (e) {
                throw e
            } finally {
                commit('SET_LOADING', false)
            }
        },
        async setPropertyType({ commit, state }, payload) {
            commit('SET_LOADING', true)
            const controller = useModule(state.module)
            try {
                if (payload.id)
                    await controller.update(payload)
                else
                    await controller.create(payload)
            } catch (e) {
                throw e
            } finally {
                commit('SET_LOADING', false)
            }
        }, async deletePropertyType({ commit, state }, payload) {
            commit('SET_LOADING', true)
            const controller = useModule(state.module)
            try {
                await controller.deleteById(payload)
            } catch (e) {
                throw e
            } finally {
                commit('SET_LOADING', false)
            }
        },
    },
}
